
const corporateCard = () => import("@/components/corporate-card");
const monitoringHistory = () => import("@/components/monitoring-history");
const monitoringSourceTree = () => import("@/components/monitoring-source-tree");

export default {
    name: "corporate-records-sidepanel",
    components: {
        monitoringHistory,
        monitoringSourceTree,
        corporateCard,
    },
    props: {
        riskCategoriesOptions: Array,
        mainEntity: Array,
        domainCompanyDetailsSearchList: Array,
        fieldType: Object,
        selected: Number,
        searchedQueryKeyword: "",
        showMoreInfo: {
            type: Boolean,
        },
        selectedQuery: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        timeline: {
            type: Array,
            default: [],
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            monitoringHistoryIndex: null,
        };
    },
    computed: {
        isMonitoringTab() {
            return true;
        },
    },
    mounted() {

    },
    methods: {
        handleStatusUpdate() {
            this.$emit("updateCardStatus")
        },
        handleStatusCount() {
            this.$emit("updateCardStatusCounts")
        },
        async showMonitoringHistory(detail) {
            const index = detail.api_all_data.doc_id;
            if (this.monitoringHistoryIndex !== index) {
                this.monitoringHistoryIndex = index;
            } else {
                this.monitoringHistoryIndex = null;
            }
            const data = {
                payload: {
                    doc_id: detail.api_all_data.doc_id,
                    query_id: detail.api_all_data.query_id,
                    tool: this.$route.query?.check_name,
                    source: detail.api_all_data.doc_source,
                    monitoringDate: detail.api_all_data.monitoringDate,
                    changes_viewed: detail.api_all_data.changes_viewed,
                    monitoringHistoryIndex: this.monitoringHistoryIndex,
                    parentMonitoringDate: detail.api_all_data.parentMonitoringDate,
                },
                detail: detail,
            };
            this.$emit("showMonitoringHistory", data);
        },
    },
};
